import React, { useState } from 'react';
import {Helmet} from "react-helmet";


const SchoolProjects = () => {

    const [css] = useState("devicon-css3-plain-wordmark");
    const [html] =useState("devicon-html5-plain-wordmark");
    const [js] = useState("devicon-javascript-plain");
    const [react] = useState("devicon-react-original-wordmark");
    const [express] = useState("devicon-express-original");
    const [sqlite] = useState("devicon-sqlite-plain")

    const [projects] = useState([
        {
            id: "fullstack-div",
            link: "https://final-full-stack-frontend.herokuapp.com/",
            githubLink: "https://github.com/stefanolami/full_stack_app_with_react_and_a_rest_api-v1",
            img: "images/projects-icons/database.png",
            imgAlt: "wheel of science icon",
            title: "SCHOOL COURSES APP",
            desc: "Full Stack App, stores and manages school courses",
            skills: [react, express, sqlite]
        },
        
        {
            id: "puppy-div",
            link: "https://stefanolami.github.io/dont_forget_the_puppy/",
            githubLink: "https://github.com/stefanolami/dont_forget_the_puppy",
            img: "images/projects-icons/dontforgetthepuppy.png",
            imgAlt: "don't forget the puppy icon",
            title: "DON'T FORGET THE PUPPY",
            desc: "Browser game about puppies",
            skills: [html, css, js]
        },
        {
            id: "form-div",
            link: "https://stefanolami.github.io/interactive-form-v3/",
            githubLink: "https://github.com/stefanolami/interactive-form-v3",
            img: "images/projects-icons/registrationform.png",
            imgAlt: "online registration form icon",
            title: "INTERACTIVE FORM",
            desc: "Interactive Form for registration on a Full Stack Conference",
            skills: [html, css, js]
        },
        {
            id: "photo-div",
            link: "https://stefanolami.github.io/photo_gallery_v5/",
            githubLink: "https://github.com/stefanolami/photo_gallery_v5",
            img: "images/projects-icons/photogallery.png",
            imgAlt: "photo gallery icon",
            title: "INTERACTIVE PHOTO GALLERY",
            desc: "Photo gallery with an interactive modal window",
            skills: [html, css, js]
        },
        {
            id: "dashboard-div",
            link: "https://stefanolami.github.io/web_app_dashboard_v3.2/",
            githubLink: "https://github.com/stefanolami/web_app_dashboard_v3.2",
            img: "images/projects-icons/webappdashboard.png",
            imgAlt: "web app dashboard icon",
            title: "WEB APP DASHBOARD",
            desc: "Dashboard with charts graphs and members activities",
            skills: [html, css, js]
        },
        {
            id: "directory-div",
            link: "https://stefanolami.github.io/data-pagination-and-filtering-v1/",
            githubLink: "https://github.com/stefanolami/data-pagination-and-filtering-v1",
            img: "images/projects-icons/emplyeedirectory.png",
            imgAlt: "students directory icon",
            title: "STUDENTS DIRECTORY",
            desc: "Web page showing random students informations fetched from an API",
            skills: [html, css, js]
        }
    ])

    return (
        <React.Fragment>
            <Helmet>
                <title>Stefano Lami - School Projects</title>
            </Helmet>
            <div className="projects">
                <a id="back-to-me-1" href="/">
                    <img src="./images/backtome.png" alt="back to me" className="back-to-me" />
                </a>
                {
                    projects?.map((project, index) => {
                        return (
                            <div className="project-div" id={project.id} key={index}>
                                <a href={project.link} target="blank" className="project-link" >
                                    <img src={`${project.img}`} alt={project.imgAlt} className="project-icon" />
                                </a>
                                <a href={project.link} target="blank">
                                    <h3>{project.title}</h3>
                                </a>
                                <p>{project.desc}</p>
                                <a href={project.githubLink} target="blank" className="project-githubLink">
                                    <svg viewBox="0 0 128 128" className="github-svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M64 5.103c-33.347 0-60.388 27.035-60.388 60.388 0 26.682 17.303 49.317 41.297 57.303 3.017.56 4.125-1.31 4.125-2.905 0-1.44-.056-6.197-.082-11.243-16.8 3.653-20.345-7.125-20.345-7.125-2.747-6.98-6.705-8.836-6.705-8.836-5.48-3.748.413-3.67.413-3.67 6.063.425 9.257 6.223 9.257 6.223 5.386 9.23 14.127 6.562 17.573 5.02.542-3.903 2.107-6.568 3.834-8.076-13.413-1.525-27.514-6.704-27.514-29.843 0-6.593 2.36-11.98 6.223-16.21-.628-1.52-2.695-7.662.584-15.98 0 0 5.07-1.623 16.61 6.19C53.7 35 58.867 34.327 64 34.304c5.13.023 10.3.694 15.127 2.033 11.526-7.813 16.59-6.19 16.59-6.19 3.287 8.317 1.22 14.46.593 15.98 3.872 4.23 6.215 9.617 6.215 16.21 0 23.194-14.127 28.3-27.574 29.796 2.167 1.874 4.097 5.55 4.097 11.183 0 8.08-.07 14.583-.07 16.572 0 1.607 1.088 3.49 4.148 2.897 23.98-7.994 41.263-30.622 41.263-57.294C124.388 32.14 97.35 5.104 64 5.104z"></path><path d="M26.484 91.806c-.133.3-.605.39-1.035.185-.44-.196-.685-.605-.543-.906.13-.31.603-.395 1.04-.188.44.197.69.61.537.91zm2.446 2.729c-.287.267-.85.143-1.232-.28-.396-.42-.47-.983-.177-1.254.298-.266.844-.14 1.24.28.394.426.472.984.17 1.255zM31.312 98.012c-.37.258-.976.017-1.35-.52-.37-.538-.37-1.183.01-1.44.373-.258.97-.025 1.35.507.368.545.368 1.19-.01 1.452zm3.261 3.361c-.33.365-1.036.267-1.552-.23-.527-.487-.674-1.18-.343-1.544.336-.366 1.045-.264 1.564.23.527.486.686 1.18.333 1.543zm4.5 1.951c-.147.473-.825.688-1.51.486-.683-.207-1.13-.76-.99-1.238.14-.477.823-.7 1.512-.485.683.206 1.13.756.988 1.237zm4.943.361c.017.498-.563.91-1.28.92-.723.017-1.308-.387-1.315-.877 0-.503.568-.91 1.29-.924.717-.013 1.306.387 1.306.88zm4.598-.782c.086.485-.413.984-1.126 1.117-.7.13-1.35-.172-1.44-.653-.086-.498.422-.997 1.122-1.126.714-.123 1.354.17 1.444.663zm0 0"></path>
                                    </svg>
                                </a>
                                <div className="skills">
                                {
                                    project.skills.map((skill, index) => {
                                        return (
                                            <div key={index}>
                                                <i className={skill} id={skill}></i>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        )
                    })
                }
                <a href="/" id="back-to-me-2">
                    <img src="./images/backtome.png" alt="back to me" className="back-to-me" />
                </a>
            </div>
        </React.Fragment>
    )
}

export default SchoolProjects;