import React, {useState} from "react";
import {Helmet} from "react-helmet";

const Home = () => {

    const [hiddenContacts, setHiddenContacts] = useState(false)

    const displayContacts = () => {
        if (hiddenContacts) {
            setHiddenContacts(false)
        } else {
            setHiddenContacts(true)
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Stefano Lami - Home</title>
            </Helmet>
            <div className="personal" id="personal">
                <div className="title-div">
                    <h1 className="title">I AM STEFANO LAMI,<br /> YOUR WEB DEVELOPER</h1>
                    <a href="/about"><button className="projects-btn" id="about-btn">ABOUT ME</button></a>
                    <a href="/projects"><button className="projects-btn">CHECKOUT MY PROJECTS</button></a>
                </div>
                <div className="profilepic-div">
                    <button className="projects-btn">CHECKOUT MY PROJECTS</button>
                    <img src="images/portfolio-alex-2.png" alt="" className="profile-pic" />
                    <div className="profile-pic-background" />
                    <div className="contacts">
                        <img src="images/getintouch-black.png" alt="" className="getintouch" onClick ={displayContacts} />
                        
                        {
                            hiddenContacts ? (
                                <div className="hidden-contacts">
                                    <img src="images/arrow-nobackground.png" alt="" className="arrow" />
                                    <a href="https://www.linkedin.com/" target="blank" className="linkedln">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
                                            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                                        </svg>
                                    </a>
                                    <a href="mailto:stefanolami90@gmail.com" className="mail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
                                            <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/>
                                        </svg>
                                    </a>
                                </div>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )
                        }
                            
                        
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Home;