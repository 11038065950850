import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import Home from './components/Home';
import Projects from './components/Projects';
import SchoolProjects from './components/SchoolProjects';
import About from './components/About';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/school-projects" element={<SchoolProjects />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
